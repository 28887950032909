import { httpCall } from 'functions/httpAction';
import { firebaseAuth } from './firebaseConfig';
// import { cookies } from 'next/headers'
import { setCookie } from 'cookies-next';

export const redirectClose = () => {
  history.back();
};

export const redirectSelectPage = () => {
  window.location = '/login';
};

export const redirectEmailLogin = () => {
  window.location = '/email';
};

export const redirectForgetPassword = () => {
  window.location = '/forgetpassword';
};

export const redirectPhoneAuth = () => {
  window.location = '/phone';
};

export const redirectSMSAuth = () => {
  window.location = '/verification';
};

export const redirectMain = (isShowComment = false) => {
  if (isShowComment) {
    window.location = '/?showComment=true';
  } else {
    window.location = '/';
  }
};

async function setReferralCookies(data) {
  setCookie('referralCode', data.used_referral_code);
}

export const loginHandle = (data, redirectPathname) => {
  setReferralCookies(data);
  if (data.phone_verified == false) {
    window.location = '/phone';
  } else if (data.post_signup_questionnaire_filled == false) {
    window.location = '/question1';
  } else {
    window.location = `${redirectPathname}`;
  }
};

export async function fetchUserContent(token) {
  try {
    const [success, error, statusCode, data] = await httpCall(
      'get',
      process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me',
      token,
      null
    );
    return { success, error, data };
  } catch (error) {
    console.log('api error:', error);
  }
}

export async function postUserQuestionaire(key, value) {
  const auth = firebaseAuth;
  const user = auth.currentUser;
  const token = user.accessToken;
  const passObject = JSON.stringify({ [key]: value });

  console.log({ token });

  try {
    const [success, error, statusCode, data] = await httpCall(
      'post',
      process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me/questionnaire/postSignUp',
      token,
      passObject
    );
    return { success, error, data };
  } catch (error) {
    console.log('api error:', error);
  }
}

export async function postUserAdvancedQuestionaire(key, value) {
  const auth = firebaseAuth;
  const user = auth.currentUser;
  const token = user.accessToken;
  const passObject = JSON.stringify({ [key]: value });

  console.log({ token });

  try {
    const [success, error, statusCode, data] = await httpCall(
      'post',
      process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me/questionnaire/advanced',
      token,
      passObject
    );
    return { success, error, data };
  } catch (error) {
    console.log('api error:', error);
  }
}

// export async function completeSignUp(inviteCode) {
//   const auth = firebaseAuth;
//   const user = auth.currentUser;
//   const token = user.accessToken;
//   const passObject = JSON.stringify({ referral_code: inviteCode });

//   // console.log({ passObject })

//   try {
//     const [success, error, statusCode, data] = await httpCall(
//       'post',
//       process.env.NEXT_PUBLIC_API_HOST + '/api/v2/signUp',
//       token,
//       passObject
//     );
//     return { success, error, data };
//   } catch (error) {
//     console.log('api error:', error);
//   }
// }
